<template>
  <div v-if="loading">加载中...</div>
  <div v-else-if="error">加载出错: {{ error.message }}</div>
  <div v-else class="home">
    <template v-if="pageConfig && pageConfig.sections">
      <template v-for="(section, index) in pageConfig.sections" :key="index">
        <!-- AI 介绍 -->
        <template v-if="section.title === 'AI 介绍'">
          <DynamicTitle
            :level="'h2'"
            :showIcon="false"
            :more="false"
            :borderBottom="true"
            :titleMark="true"
          >
            {{ section.title }}
          </DynamicTitle>
          <div v-html="section.content" class="mb-4"></div>
        </template>

        <!-- AI 频道 -->
        <template v-if="section.title === 'AI 频道'">
          <DynamicTitle
            :level="'h3'"
            :showIcon="true"
            :icon="['fal', 'arrow-turn-down-right']"
            :more="false"
            :moreUrl="'#'"
            :borderBottom="false"
            :titleMark="false"
          >
            AI 细分领域简介
          </DynamicTitle>
          <div class="grid md:grid-cols-2 xl:grid-cols-4 gap-4 mb-4">
            <ChannelCard 
              v-for="(channelData, channelIndex) in sectionData[section.title]?.items"
              :key="channelIndex"
              v-bind="channelData"
              :showTags="false" 
              :showAction="false"
            />
          </div>
        </template>

        <!-- AI 工作流 -->
        <template v-if="section.title === 'AI 工作流'">
          <DynamicTitle
            :level="'h3'"
            :showIcon="true"
            :icon="['fal', 'arrow-turn-down-right']"
            :more="false"
            :moreUrl="'#'"
            :borderBottom="false"
            :titleMark="false"
          >
            {{ section.title }}
          </DynamicTitle>
          <div class="grid md:grid-cols-2 xl:grid-cols-4 gap-4">
            <AppCard
            v-for="(productData, productIndex) in sectionData[section.title]?.items"
              :key="productIndex"
              :appcardItems="[productData]"
              :showLogo="true"
              :showPriceline="false"
              :showAuthor="false"
              :showTags="true"
              :showBtn="true"
            />
          </div>
        </template>

        <!-- 热门课程 -->
        <template v-if="section.title === '热门课程'">
          <DynamicTitle
            :level="'h2'"
            :showIcon="false"
            :more="true"
            :moreUrl="'/all-course'"
            :borderBottom="true"
            :titleMark="true"
          >
            {{ section.title }}
          </DynamicTitle>
          <div class="grid md:grid-cols-2 xl:grid-cols-4 gap-4">
            <AppCard
              v-for="(courseData, courseIndex) in sectionData[section.title]?.items"
              :key="courseIndex"
              :appcardItems="[courseData]"
              :showLogo="true"
              :showPriceline="true"
              :showAuthor="true"
              :showTags="false"
              :showBtn="false"
            />
          </div>
        </template>

        <!-- 热门产品 -->
        <template v-if="section.title === '热门产品'">
          <DynamicTitle
            :level="'h2'"
            :showIcon="false"
            :more="true"
            :moreUrl="'/all-product'"
            :borderBottom="true"
            :titleMark="true"
          >
            {{ section.title }}
          </DynamicTitle>
          <div class="grid md:grid-cols-2 xl:grid-cols-4 gap-4 mb-4">
            <AppCard
              v-for="(productData, productIndex) in sectionData[section.title]?.items"
              :key="productIndex"
              :appcardItems="[productData]"
              :showLogo="true"
              :showPriceline="false"
              :showAuthor="true"
              :showTags="true"
              :showBtn="false"
              :btnIcon="['far', 'arrow-right']"
            />
          </div>
        </template>

        <!-- 热门开源项目 -->
        <template v-if="section.title === '热门开源项目'">
          <DynamicTitle
            :level="'h2'"
            :showIcon="false"
            :more="true"
            :moreUrl="'/all-opensource'"
            :borderBottom="true"
            :titleMark="true"
          >
            {{ section.title }}
          </DynamicTitle>
          <div class="grid md:grid-cols-2 xl:grid-cols-4 gap-4 mb-4">
            <LinkCard 
              v-for="(opensourceData, opensourceIndex) in sectionData[section.title]?.items"
              :key="opensourceIndex"
              :linkcardItems="[opensourceData]"
              :showTags="true" 
              :showAction="false"
            />
          </div>
        </template>

        <!-- 最近更新 -->
        <template v-if="section.title === '最近更新'">
          <DynamicTitle
            :level="'h2'"
            :showIcon="false"
            :more="true"
            :moreUrl="'/all-update'"
            :borderBottom="true"
            :titleMark="true"
          >
            {{ section.title }}
          </DynamicTitle>
          <PillsFilter mode="single" v-model="selectedSingleFilter" />
          <div class="grid md:grid-cols-2 xl:grid-cols-3 gap-1 mb-4">
            <UpdateItem
              :updateItems="sectionData[section.title]?.items"
              :showTags="true"
            />
          </div>
        </template>

        <!-- AI 时间线 -->
        <template v-if="section.title === 'AI 时间线'">
          <DynamicTitle
            :level="'h2'"
            :showIcon="false"
            :more="true"
            :moreUrl="'/time-line'"
            :borderBottom="true"
            :titleMark="true"
          >
            {{ section.title }}
          </DynamicTitle>
          <PillsFilter mode="multi" v-model="selectedMultiFilters" />
          <TimeLine 
            :timelineItems="sectionData[section.title]?.items"
            :showOrigin="true"
            :showBrandLogo="true"
            :showBrandName="true"
            :showTimeAgo="true"
            :showTags="true"
          />
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import { usePageData } from '@/components/usePageData';
import LinkCard from '@/components/LinkCard.vue'
import AppCard from '@/components/AppCard.vue'
import DynamicTitle from '@/components/DynamicTitle.vue'
import UpdateItem from '@/components/UpdateItem.vue'
import PillsFilter from '@/components/PillsFilter.vue'
import TimeLine from '@/components/TimeLine.vue'
import ChannelCard from '@/components/ChannelCard.vue'

export default {
  name: 'HomePage',
  components: {
    AppCard,
    DynamicTitle,
    LinkCard,
    UpdateItem,
    PillsFilter,
    TimeLine,
    ChannelCard,
  },
  setup() {
    const { pageConfig, sectionData, loading, error, applyFilters } = usePageData('home', 'homePage');
    const selectedSingleFilter = ref('all');
    const selectedMultiFilters = ref(['all']);

    watch(() => pageConfig.value, (newValue) => {
      console.log("pageConfig updated:", JSON.parse(JSON.stringify(newValue)));
    }, { deep: true });

    watch(() => sectionData, (newValue) => {
      console.log("sectionData updated:", JSON.parse(JSON.stringify(newValue)));
    }, { deep: true });

    const handleSingleFilterChange = async (filter) => {
      const updateSection = pageConfig.value.sections.find(s => s.title === '最近更新');
      if (updateSection) {
        await applyFilters(updateSection.title, { type: filter });
      }
    };

    const handleMultiFilterChange = async (filters) => {
      const timelineSection = pageConfig.value.sections.find(s => s.title === 'AI 时间线');
      if (timelineSection) {
        await applyFilters(timelineSection.title, { tags: filters });
      }
    };

    watch(selectedSingleFilter, handleSingleFilterChange);
    watch(selectedMultiFilters, handleMultiFilterChange);

    watch(() => sectionData['AI 工作流'], (newValue) => {
      console.log("AI 工作流 section data updated:", JSON.parse(JSON.stringify(newValue)));
    }, { deep: true });

    return {
      pageConfig,
      sectionData,
      loading,
      error,
      selectedSingleFilter,
      selectedMultiFilters,
    };
  },
};
</script>
