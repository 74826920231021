import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';

const routes = [
  { path: '/', name: 'HomePage', component: HomePage },
  {
    path: '/time-line',
    name: 'TimeLine',
    component: () => import('@/views/TimeLine.vue'),
    meta: {
      title: '时间线',
    }
  },
  {
    path: '/channel-list',
    name: 'ChannelList',
    component: () => import('@/views/ChannelList.vue'),
    meta: {
      title: '频道列表',
    }
  },
  {
    path: '/hot-product',
    name: 'HotProduct',
    component: () => import('@/views/HotProduct.vue'),
    meta: {
      title: '热门产品',
    }
  },
  {
    path: '/product-page',
    name: 'ProductPage',
    component: () => import('@/views/ProductPage.vue'),
    meta: {
      title: '热门产品',
    }
  },
  {
    path: '/ai-basics',
    name: 'AIBasics',
    component: () => import('@/views/AIBasics.vue'),
    meta: {
      title: '免费 AI 知识库',
    }
  },
  {
    path: '/knowledge-base',
    name: 'KnowledgeBase',
    component: () => import('@/pages/KnowledgeBase.vue'),
    meta: {
      title: '免费 AI 知识库',
    }
  },
  {
    path: '/subscribe-vip',
    name: 'SubscribeVIP',
    component: () => import('@/pages/SubscribeVIP.vue'),
    meta: {
      title: '订阅 VIP',
    }
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: () => import('@/pages/AboutUs.vue'),
    meta: {
      title: '关于我们',
    }
  },
  {
    path: '/channel-index',
    name: 'ChannelIndex',
    component: () => import('@/views/ChannelIndex.vue'),
    meta: {
      title: ' 频道首页',
    }
  },
  // more pages
  {
    path: '/all-article',
    name: 'AllArticle',
    component: () => import('@/views/AllArticle.vue')
  },
  {
    path: '/all-course',
    name: 'AllCourse',
    component: () => import('@/views/AllCourse.vue')
  },
  {
    path: '/all-opensource',
    name: 'AllOpensource',
    component: () => import('@/views/AllOpensource.vue')
  },
  {
    path: '/all-product',
    name: 'AllProduct',
    component: () => import('@/views/AllProduct.vue')
  },
  {
    path: '/all-tool',
    name: 'AllTool',
    component: () => import('@/views/AllTool.vue')
  },
  {
    path: '/all-update',
    name: 'AllUpdate',
    component: () => import('@/views/AllUpdate.vue')
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});

export default router;
