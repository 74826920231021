import { ref, reactive, inject } from 'vue';
import gql from 'graphql-tag';

export const useDataFetcher = () => {
  const apolloClient = inject('apollo');

  const state = reactive({
    pageConfig: null,
    sectionData: {},
    error: null,
  });

  const pageConfigRef = ref(null);

  const getPageConfig = async (pageId, pageType) => {
    console.log(`1.Attempting to fetch page config for pageId: ${pageId}, pageType: ${pageType}`);
    const PAGE_CONFIG_QUERY = gql`
      query GetPageConfig($pageId: String!, $pageType: String!) {
        getPageConfig(pageId: $pageId, pageType: $pageType) {
          _id
          pageType
          sections {
            title
            query
            variables
            fields
            filters {
              type
              field
              options
            }
            specificItems
            content
            items
            collection
          }
        }
      }
    `;

    try {
      const { data, error } = await apolloClient.query({
        query: PAGE_CONFIG_QUERY,
        variables: { pageId, pageType },
        fetchPolicy: 'network-only'
      });

      console.log("2.Apollo query result:", data);

      if (error) {
        console.error("Apollo error:", error);
        throw error;
      }

      if (data && data.getPageConfig) {
        console.log("3.Page config fetched successfully:", data.getPageConfig);
        console.log("4.Page config structure:", JSON.stringify(data.getPageConfig, null, 2));
        pageConfigRef.value = data.getPageConfig;
        state.pageConfig = data.getPageConfig;
        console.log("5.pageConfigRef after assignment:", pageConfigRef.value);
        return { result: data, loading: false, error: null };
      } else {
        console.warn("No page config data received");
        throw new Error("No page config data received");
      }
    } catch (error) {
      console.error("Error fetching page config:", error);
      throw error;
    }
  };

  const createDynamicQuery = (queryName, fields = []) => {
    // 如果 fields 为空，使用默认字段
    const defaultFields = ['_id', 'title', 'desc'];
    const queryFields = fields.length > 0 ? fields : defaultFields;

    return gql`
      query ${queryName}($filters: JSON) {
        ${queryName}(filters: $filters) {
          ${queryFields.join('\n')}
        }
      }
    `;
  };

  const createComparesQuery = (fields = []) => {
    const defaultFields = ['_id', 'logo', 'title', 'desc'];
    let queryFields = fields.length > 0 ? fields : defaultFields;

    // 移除 chart 字段，我们将单独处理它
    queryFields = queryFields.filter(field => field !== 'chart');

    return gql`
      query GetCompares {
        getCompares {
          ${queryFields.join('\n')}
          chart {
            axis
            value
          }
        }
      }
    `;
  };

  const fetchSectionData = async (section) => {
    try {
      let dynamicQuery;
      if (section.query === 'getCompares') {
        dynamicQuery = createComparesQuery(section.fields);
        console.log('Using getCompares query:', dynamicQuery.loc.source.body);
      } else {
        dynamicQuery = createDynamicQuery(section.query, section.fields);
        console.log('Using dynamic query:', dynamicQuery.loc.source.body);
      }

      console.log('Generated query:', dynamicQuery.loc.source.body); // 添加这行来打印生成的查询

      const variables = {
        filters: {
          ...section.variables,
        }
      };

      const { data, error } = await apolloClient.query({
        query: dynamicQuery,
        variables: variables,
        fetchPolicy: 'network-only'
      });

      if (error) {
        console.error(`Error fetching data for section ${section.title}:`, error);
        console.error('GraphQL errors:', error.graphQLErrors);
        console.error('Network error:', error.networkError);
        throw error;
      }

      console.log(`Data received for section ${section.title}:`, data);
      return { result: data };
    } catch (error) {
      console.error(`Error in fetchSectionData for ${section.title}:`, error);
      console.error('Full error object:', JSON.stringify(error, null, 2));
      throw error;
    }
  };

  const fetchSpecificItems = async (collection, ids, fields) => {
    console.log(`Attempting to fetch specific items for collection ${collection}, ids: ${ids}, fields: ${fields}`);
    const SPECIFIC_ITEMS_QUERY = gql`
      query GetSpecificItems($collection: String!, $ids: [ID!]!, $fields: [String!]!) {
        getSpecificItems(collection: $collection, ids: $ids, fields: $fields) {
          _id
          ${fields.filter(field => field !== '_id').map(field => `${field}`).join('\n')}
        }
      }
    `;

    try {
      const { data, error } = await apolloClient.query({
        query: SPECIFIC_ITEMS_QUERY,
        variables: { collection, ids, fields },
        fetchPolicy: 'network-only'
      });

      if (error) {
        console.error(`Error fetching specific items for collection ${collection}:`, error);
        throw error;
      }

      if (data && data.getSpecificItems) {
        console.log(`Fetched specific items for collection ${collection}:`, data.getSpecificItems);
        return data.getSpecificItems;
      } else {
        console.error(`No data received for specific items, collection: ${collection}`);
        throw new Error(`未收到特定项目的数据，集合：${collection}`);
      }
    } catch (error) {
      console.error(`获取特定项目数据时出错，集合：${collection}:`, error);
      throw error;
    }
  };

  const applyFilters = async (section, filters) => {
    const dynamicQuery = createDynamicQuery(section.query, section.fields);
    
    try {
      const variables = {
        filters: {
          ...filters,
          limit: section.variables?.limit,
          offset: section.variables?.offset
        }
      };

      const { data, error } = await apolloClient.query({
        query: dynamicQuery,
        variables: variables,
        fetchPolicy: 'network-only'
      });

      if (error) {
        console.error(`Error applying filters for section ${section.title}:`, error);
        state.error = error;
        throw error;
      }

      if (data && data[section.query]) {
        state.sectionData[section.title] = data[section.query];
        return { result: data, loading: false, error: null };
      } else {
        console.warn(`No data received after applying filters for section ${section.title}`);
        throw new Error(`No data received after applying filters for section ${section.title}`);
      }
    } catch (error) {
      console.error(`Error applying filters for section ${section.title}:`, error);
      throw error;
    }
  };

  return {
    state,
    pageConfigRef,
    getPageConfig,
    fetchSectionData,
    fetchSpecificItems,
    applyFilters,
  };
};