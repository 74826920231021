<template>
  <div class="pills-filter flex flex-wrap items-center">
    <div 
      v-for="item in filterItems" 
      :key="item.value"
      class="rounded py-1 px-2 text-sm cursor-pointer transition-colors duration-300 ease-in-out mr-2 mb-2 flex items-center"
      :class="{ 'bg-blue-600 text-white': isActive(item.value) }"
      @click="toggleFilter(item.value)"
    >
      <template v-if="mode === 'multi'">
        <span v-if="item.value === 'all'" class="mr-1">
          <font-awesome-icon :icon="['far', 'check-double']" />
        </span>
        <span v-else class="mr-1">
          <font-awesome-icon :icon="isActive(item.value) ? 'fas fa-check-square' : 'far fa-square'" />
        </span>
      </template>
      {{ item.label }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'PillsFilter',
  props: {
    mode: {
      type: String,
      default: 'single'
    },
    modelValue: {
      type: [String, Array],
      default: () => ''
    }
  },
  data() {
    return {
    selectedSingleFilter: 'all',  // 用于单选模式
    selectedMultiFilters: ['all'], // 用于多选模式
      filterItems: [
        { value: 'all', label: '全部', checked: false },
        { value: 'midjourney', label: 'midjourney', checked: false },
        { value: 'stablediffusion', label: 'stablediffusion', checked: false },
        { value: 'chatGPT', label: 'chatGPT', checked: false },
        { value: 'LLM', label: 'LLM', checked: false },
        { value: 'AI绘画', label: 'AI绘画', checked: false }
      ],
      internalValue: this.mode === 'single' ? '' : []
    };
  },
  created() {
    this.initializeValue();
  },
  computed: {
  filterConfigs() {
      return [
        { mode: 'single', value: this.selectedSingleFilter },
        { mode: 'multi', value: this.selectedMultiFilters },
        // 可以根据需要添加更多配置
      ];
    }
  },
  methods: {
    initializeValue() {
      if (this.mode === 'single') {
        this.internalValue = this.modelValue || 'all';
      } else {
        this.internalValue = Array.isArray(this.modelValue) && this.modelValue.length ? [...this.modelValue] : ['all'];
      }
      this.updateCheckStatus();
    },
    isActive(value) {
      return this.mode === 'single' ? this.internalValue === value : this.internalValue.includes(value);
    },
    toggleFilter(value) {
      if (this.mode === 'single') {
        this.internalValue = value;
      } else {
        if (value === 'all') {
          this.internalValue = ['all'];
        } else {
          const allIndex = this.internalValue.indexOf('all');
          if (allIndex > -1) {
            this.internalValue.splice(allIndex, 1);
          }
          
          const itemIndex = this.internalValue.indexOf(value);
          if (itemIndex > -1) {
            this.internalValue.splice(itemIndex, 1);
          } else {
            this.internalValue.push(value);
          }
          
          if (this.internalValue.length === 0) {
            this.internalValue = ['all'];
          }
        }
      }
      this.updateCheckStatus();
      this.emitValue();
    },
    updateCheckStatus() {
      this.filterItems.forEach(item => {
        item.checked = this.isActive(item.value);
      });
    },
    emitValue() {
      this.$emit('update:modelValue', this.internalValue);
    },
    updateFilter(field, value) {
      // 清理值中的 $ 符号
      const cleanValue = typeof value === 'string' ? value.replace(/\$/g, '') : value;
      this.$emit('update:modelValue', { ...this.modelValue, [field]: cleanValue });
    }
  },
  watch: {
    modelValue: {
      handler(newValue) {
        if (this.mode === 'single' && typeof newValue === 'string') {
          this.internalValue = newValue;
        } else if (this.mode === 'multi' && Array.isArray(newValue)) {
          this.internalValue = [...newValue];
        } else {
          this.initializeValue();
        }
        this.updateCheckStatus();
      },
      immediate: true
    },
    mode: {
      handler() {
        this.initializeValue();
      },
      immediate: true
    }
  },
};
</script>

<style scoped>
.pill:not(.pill-active) {
  @apply bg-gray-200 text-gray-600;
}
</style>