import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client/core';
import { provideApolloClient } from '@vue/apollo-composable';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

// 创建 HTTP 链接，指向您的 GraphQL 服务器
const httpLink = createHttpLink({
  uri: 'https://gql.aicc.pro/graphql', // 替换为您的 GraphQL 接口地址
});

// 设置 API 密钥
const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'x-api-key': 'baboon-jackson-inrush-DIVIDE',  // 这里添加 API 密钥
    }
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(`GraphQL error: ${message}, Path: ${path}, Location: ${locations}`);
    });
  }
  if (networkError) {
    console.error(`Network error: ${networkError}`);
  }
});

const apolloClient = new ApolloClient({
  link: errorLink.concat(authLink).concat(httpLink),
  cache: new InMemoryCache(),
});

// 将 Apollo Client 提供给整个应用
provideApolloClient(apolloClient);

export default apolloClient;
