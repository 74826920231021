import { ref, reactive, onMounted } from 'vue';
import { useDataFetcher } from '@/components/DataFetcher';

export function usePageData(pageId, pageType) {
  const dataFetcher = useDataFetcher();
  const pageConfig = ref(null);
  const sectionData = reactive({});
  const loading = ref(true);
  const error = ref(null);

  const fetchSectionData = async (section, filters = {}) => {
    try {
      console.log(`Fetching section data for: ${section.title}`, section);
      sectionData[section.title] = sectionData[section.title] || {};
  
      if (section.content) {
        sectionData[section.title].content = section.content;
      }
  
      if (section.items) {
        sectionData[section.title].items = section.items;
      }
  
      if (section.collection) {
        sectionData[section.title].collection = section.collection;
      }
  
      if (section.specificItems) {
        if (!section.collection) {
          console.error(`Missing collection for section: ${section.title}`);
          return;
        }
        const fields = section.fields || ['_id', 'title', 'desc', 'coverUrl', 'logoUrl', 'author', 'authorAvatar', 'link', 'createdAt'];
        const result = await dataFetcher.fetchSpecificItems(section.collection, section.specificItems, fields);
        sectionData[section.title].items = result;
      } else if (section.query) {
        const fields = section.fields || ['_id', 'title', 'desc'];
        const result = await dataFetcher.fetchSectionData({
          ...section,
          fields,
          variables: {
            ...section.variables,
            ...filters,
            limit: section.variables?.limit,
            offset: section.variables?.offset
          }
        });

        if (result && result.result && result.result[section.query]) {
          sectionData[section.title].items = result.result[section.query];
        } else {
          console.warn(`No query result for ${section.title}`);
        }
      }

      console.log(`Fetched data for section ${section.title}:`, sectionData[section.title]);
    } catch (err) {
      console.error(`获取 ${section.title} 部分数据时出错:`, err);
      sectionData[section.title].error = err.message;
    }
  };

  const fetchAllSectionData = async (config) => {
    if (config && Array.isArray(config.sections)) {
      for (const section of config.sections) {
        await fetchSectionData(section);
      }
    }
  };

  const applyFilters = async (sectionTitle, filters) => {
    const section = pageConfig.value.sections.find(s => s.title === sectionTitle);
    if (section) {
      await fetchSectionData(section, filters);
    }
  };

  onMounted(async () => {
    try {
      const { result } = await dataFetcher.getPageConfig(pageId, pageType);
      if (result && result.getPageConfig) {
        pageConfig.value = result.getPageConfig;
        await fetchAllSectionData(pageConfig.value);
      } else {
        throw new Error("无效或不完整的页面配置");
      }
    } catch (err) {
      console.error("加载页面数据时出错:", err);
      error.value = err;
    } finally {
      loading.value = false;
    }
  });

  return {
    pageConfig,
    sectionData,
    loading,
    error,
    applyFilters
  };
}
