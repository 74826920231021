<template>
    <component :is="link ? 'router-link' : 'div'"
        :to="link"
        :class="[
            'link-card',
            {
                'card-hover': !isDisabled,
                'disabled': isDisabled
            }
        ]"
        @click="handleClick"
    >
        <div class="content flex flex-row gap-4 p-2">
            <!-- Logo 区域 -->
            <div class="flex items-center">
                <img :src="logo" alt="Logo" class="logo w-20 h-20 rounded-full ms-2">
            </div>
            <div class="prop flex-grow flex flex-col gap-1">
                <div class="product text-lg">{{ title }}</div>
                <p class="brand text-xs text-text-mid">{{ subtitle }}</p>
                <p class="desc text-sm text-text-normal">{{ desc }}</p>
            </div>
            <!-- 链接按钮，通过开关控制显示 -->
            <div v-if="showAction && !isDisabled" class="btn flex items-center justify-center mt-4">
                <font-awesome-icon :icon="actionIcon" class="opacity-50 group-hover:opacity-100 group-hover:text-brand-primary mr-2 text-2xl" />
            </div>
        </div>
    </component>
</template>

<script>
export default {
    name: 'ChannelCard',
    props: {
        logo: {
            type: String,
            default: require('@/assets/cardlogo.png')
        },
        title: {
            type: String,
            default: 'ChatGPT 4o'
        },
        subtitle: {
            type: String,
            default: 'OpenAI'
        },
        desc: {
            type: String,
            default: '一个能够和玩家玩游戏的AI智能...'
        },
        showAction: {
            type: Boolean,
            default: true
        },
        actionIcon: {
            type: Array,
            default: () => ['far', 'fa-circle-arrow-right'] 
        },
        link: {
            type: [String, Object],
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isDisabled() {
            return this.disabled || !this.link;
        }
    },
    methods: {
        handleClick(event) {
            if (this.isDisabled) {
                event.preventDefault();
            }
        }
    }
}
</script>

<style scoped>
.disabled {
    @apply rounded-lg bg-white border border-slate-200 opacity-65 block;
    opacity: 0.6;
    cursor: not-allowed;
}

.disabled:hover {
    /* 覆盖任何可能的悬停效果 */
    opacity: 0.6;
    cursor: not-allowed;
}
</style>