<template>
  <aside class="sidebar p-6 w-64 h-full flex flex-col bg-white">
    <AppHeader />
    <ul class="flex-grow flex flex-col gap-0.5">
      <li v-for="menu in menus" :key="menu.menuUrl">
        <router-link
          class="text-text-normal hover:text-brand-primary px-6 hover:bg-brand-light-bg rounded-3xl block py-2 transition-colors duration-200"
          :class="{ 'active': isActive(menu.menuUrl) }"
          :to="menu.menuUrl"
          @click="handleItemClick"
          >
          <font-awesome-icon 
            class="me-2 opacity-70 fa-fw" 
            :icon="[isActive(menu.menuUrl) ? 'fas' : 'far', menu.menuIcon[1]]" 
          />
          {{ menu.menuName }}
        </router-link>
      </li>
    </ul>
    <AD></AD>
    <FriendLink></FriendLink>
  </aside>
</template>

<script>
import AppHeader from "./AppHeader.vue";
import AD from "./AD.vue";
import FriendLink from "./FriendLink.vue";
import menus from "@/data/menu.json"

export default {
  name: "AppSidebar",
  components: {
    AppHeader,
    AD,
    FriendLink,
  },
  data() {
    return {
      menus: menus
    };
  },
  methods: {
    handleItemClick() {
      this.$emit('close-sidebar');
    },
    isActive(url) {
      return this.$route.path === url;
    }
  }
};
</script>

<style scoped>
.active{
  @apply bg-brand-light-bg transition-colors duration-200;
  color: rgb(190 0 190) !important;
}
</style>