<template>
  <div
    class="card overflow-hidden card-hover group"
    @click="handleCardClick(item.link)"
    v-for="(item, index) in appcardItems"
    :key="index"
  >
    <div class="cover relative" @click.stop="handleCoverClick">
      <a :href="item.link" :target="isExternalLink(item.link) ? '_blank' : null" class="relative block">
        <img :src="item.cover" alt="Card cover" class="w-full h-auto" />
        <div class="absolute inset-0 bg-black opacity-10 transition-opacity duration-300 group-hover:opacity-0"></div>
      </a>
      <div
        v-if="showLogo"
        class="logo absolute rounded-full overflow-hidden bottom-[-24px] left-4 w-12 h-12"
      >
        <slot name="logo">
          <img 
            :src="getFaviconUrl(item.link)" 
            alt="Logo" 
            class="w-full h-full object-cover p-1.5" 
            @error="handleFaviconError"
          />
        </slot>
      </div>
    </div>
    
    <div class="content p-4 pt-8 flex flex-col gap-2">
      <div class="title font-bold text-brand-primary">
        <slot name="title">
          <a :href="item.link" :target="isExternalLink(item.link) ? '_blank' : null">
            {{ item.title }}
          </a>
        </slot>
      </div>
      
      <div v-if="showAuthor" class="author-info text-text-normal flex gap-2">
        <slot name="author">
          <img v-if="showAuthorAvatar"
            :src="item.avatar"
            class="w-6 h-6 rounded-full overflow-hidden object-cover"
            alt="Author avatar"
          />
          <span class="text-text-normal">{{ item.author }}</span>
        </slot>
      </div>
      
      <div class="desc text-sm leading-6 text-text-mid overflow-hidden text-ellipsis overflow-ellipsis line-clamp-3">
        <slot name="desc">{{ item.desc }}</slot>
      </div>
      
      <div v-if="showPriceline" class="priceline flex flex-row items-center">
        <slot name="priceline">
          <span class="price text-brand-primary text-lg flex-grow">{{ item.price }}</span>
          <span class="people-count text-text-normal opacity-75">
            <font-awesome-icon class="me-1" icon="far fa-users" />{{ item.peopleCount }}人学习
          </span>
        </slot>
      </div>
      
      <div v-if="showTags" class="tags flex flex-wrap gap-2">
        <slot name="tags">
          <span
            v-for="tag in item.tags"
            :key="tag"
            class="tag label bg-gray-200 text-gray-700 px-2 py-1 rounded-md"
          >
            {{ tag }}
          </span>
        </slot>
      </div>
      
      <slot name="btn">
        <a
          v-if="showBtn"
          :href="item.link"
          :target="isExternalLink(item.link) ? '_blank' : null"
          class="btn-primary hover:bg-brand-primary hover:text-white transition-colors"
        >
          <font-awesome-icon :icon="btnIcon" class="me-2" /> {{ btnText }}
        </a>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppCard",
  props: {
    appcardItems: {
      type: Array,
      required: true,
      default: () => []
    },
    showLogo: {
      type: Boolean,
      default: true
    },
    showAuthor: {
      type: Boolean,
      default: true
    },
    showAuthorAvatar: {
      type: Boolean,
      default: false
    },
    showPriceline: {
      type: Boolean,
      default: true
    },
    showTags: {
      type: Boolean,
      default: true
    },
    showBtn: {
      type: Boolean,
      default: true
    },
    btnIcon: {
      type: Array,
      default: () => ["fa-regular", "arrow-right"]
    },
    btnText: {
      type: String,
      default: "立即使用"
    },
    faviconServiceUrl: {
      type: String,
      default: "http://fav.aicc.pro/favicon"
    }
  },
  methods: {
    isExternalLink(link) {
      const externalLinkRegex = /^(https?:\/\/)|(www\.)/;
      return externalLinkRegex.test(link);
    },
    handleCardClick(link) {
      if (!this.showBtn) {
        this.$emit("card-click", link);
      }
    },
    handleCoverClick(event) {
      if (this.showBtn) {
        event.stopPropagation();
        this.$emit("cover-click");
      }
    },
    getFaviconUrl(link) {
      return `${this.faviconServiceUrl}?url=${encodeURIComponent(link)}`;
    },
    handleFaviconError(event) {
      // 当 favicon 加载失败时，使用默认图标
      event.target.src = require('@/assets/default-favicon.png');
    }
  },
};
</script>

<style scoped>
.card-hover {
  transition: transform 0.3s ease-in-out;
}

.card-hover:hover {
  transform: translateY(-5px);
}
</style>