<template>
  <div :class="['lv1-title', 'flex', 'items-center', 'mb-4', {'border-b': borderBottom}]">
    <!-- 根据 level prop 动态使用 h2 或 h3 -->
    <component :is="level" :class="textClass">
      <!-- 如果启用了图标，插入图标 -->
      <font-awesome-icon v-if="showIcon && icon.length" :icon="icon" class="me-2" />
      <!-- 插入标题文字 -->
      <slot>AI 介绍</slot>
      <!-- 如果启用 title-mark -->
      <span v-if="titleMark" class="title-mark"></span>
    </component>
    <!-- 如果启用了更多按钮，显示更多链接 -->
    <a v-if="more" :href="moreUrl" class="more-link">
      <font-awesome-icon :icon="['fal', 'circle-ellipsis']" /> 更多
    </a>
  </div>
</template>

<script>
export default {
  name: 'DynamicTitle',
  props: {
    level: {
      type: String,
      default: 'h2' // 默认为 h2
    },
    showIcon: { // 控制是否显示图标
      type: Boolean,
      default: false
    },
    icon: { // 图标设置，如果没有提供，则使用默认值
      type: Array,
      default: () => ['fal', 'arrow-turn-down-right']
    },
    more: {
      type: Boolean,
      default: false
    },
    moreUrl: {
      type: String,
      default: '#'
    },
    titleMark: {
      type: Boolean,
      default: false
    },
    borderBottom: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    textClass() {
      const baseClass = 'flex-grow relative';
      switch (this.level) {
        case 'h2':
          return `text-xl text-text-normal py-4 ${baseClass} border-b-bg-light-2`;
        case 'h3':
          return `text-base font-bold text-text-normal py-4 ${baseClass}`;
        default:
          return baseClass;
      }
    }
  }
}
</script>
<style scoped>
  .title-mark {
    @apply block h-[3px] w-[24px] bg-brand-primary absolute rounded-sm;
    bottom: -2px;
  }
</style>