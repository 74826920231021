<template>
    <div v-if="linkcardItems && linkcardItems.length > 0" class="h-full">
        <a v-for="(item, index) in linkcardItems" :key="index" :href="item.link" :target="isExternalLink(item.link) ? '_blank' : null" class="link-card overflow-hidden card-hover flex flex-col h-full">
            <div class="content flex flex-row gap-4 p-2 flex-grow">
                <!-- Logo 区域 -->
                <div v-if="showLogo" class="flex items-center flex-shrink-0">
                    <img :src="getFaviconUrl(item.link)" 
                         :alt="item.title + ' logo'" 
                         class="logo w-10 h-10 rounded-full ms-2"
                         @error="handleLogoError">
                </div>
                <div class="prop flex-grow flex flex-col justify-between">
                    <div>
                        <div class="product text-base font-bold mb-2">{{ item.title }}</div>
                        <p class="brand text-sm text-text-normal mb-2">{{ item.company }}</p>
                        <p class="desc text-sm text-text-normal mb-2 overflow-hidden text-ellipsis line-clamp-2">{{ item.desc }}</p>
                    </div>
                    <div v-if="showAction" class="btn flex items-center justify-end mt-auto">
                        <font-awesome-icon :icon="actionIcon" class="opacity-50 hover:opacity-100 hover:text-brand-primary" />
                    </div>
                </div>
            </div>
            <div v-if="showTags && item.tags" class="tags flex flex-row gap-2 bg-bg-light p-2 mt-auto">
                <span v-for="tag in item.tags" :key="tag" class="label">#{{ tag }}</span>
            </div>
        </a>
    </div>
    <p v-else>No items to display.</p>
</template>

<script>
export default {
    name: 'LinkCard',
    props: {
        linkcardItems: {
            type: Array,
            required: true,
            default: () => []
        },
        showLogo: {
            type: Boolean,
            default: true
        },
        showTags: {
            type: Boolean,
            default: true
        },
        showAction: {
            type: Boolean,
            default: true
        },
        actionIcon: {
            type: Array,
            default: () => ['far', 'fa-circle-arrow-right']
        },
        faviconServiceUrl: {
            type: String,
            default: "https://fav.aicc.pro/favicon"
        }
    },
    data() {
        return {
            defaultLogo: require('@/assets/cardlogo.png')
        }
    },
    methods: {
        isExternalLink(link) {
            const externalLinkRegex = /^(https?:\/\/)|(www\.)/;
            return externalLinkRegex.test(link);
        },
        getFaviconUrl(link) {
            return `${this.faviconServiceUrl}?url=${encodeURIComponent(link)}`;
        },
        handleLogoError(event) {
            event.target.src = this.defaultLogo;
        }
    }
}
</script>
