<template>
  <div>
    <h3 class="font-bold mb-2">Contributors</h3>
    <div class="flex items-center relative">
      <template v-for="(contributor, index) in visibleContributors" :key="contributor.name">
        <div 
          class="relative w-8 h-8 rounded-full border-2 border-white overflow-hidden"
          :class="{ 'hover:border-brand-primary': contributor.link }"
          :style="{ marginLeft: index > 0 ? '-0.5rem' : '0', zIndex: visibleContributors.length - index }"
          @mouseenter="showTooltip(index, $event)"
          @mouseleave="hideTooltip"
        >
          <component :is="contributor.link ? 'a' : 'div'"
            v-bind="contributor.link ? { href: contributor.link, target: '_blank', rel: 'noopener noreferrer' } : {}"
            class="block w-full h-full"
            :class="{ 'cursor-pointer': contributor.link }"
          >
            <img 
              :src="contributor.avatar" 
              :alt="contributor.name" 
              class="w-full h-full object-cover"
            >
          </component>
        </div>
      </template>
      <router-link 
        v-if="more > 0" 
        to="/contributor-list"
        class="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center text-xs text-gray-600 ml-[-0.5rem] border-2 border-white hover:bg-gray-300 transition-colors duration-200"
        :style="{ zIndex: 0 }"
      >
        +{{ more }}
      </router-link>
      
      <!-- Tooltip -->
      <div 
        v-if="activeTooltip !== null && visibleContributors[activeTooltip]"
        class="absolute mb-2 px-3 py-2 bg-gray-900 text-white text-sm rounded shadow-lg whitespace-nowrap z-50"
        :style="tooltipStyle"
      >
        <p class="font-bold">{{ visibleContributors[activeTooltip].name }}</p>
        <p v-if="visibleContributors[activeTooltip].link" class="text-xs text-gray-300">
          {{ visibleContributors[activeTooltip].link }}
        </p>
        <div 
          class="absolute w-0 h-0 border-x-8 border-x-transparent border-t-8 border-t-gray-900"
          :style="arrowStyle"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContributorsLink',
  props: {
    contributors: {
      type: Array,
      required: true,
      validator: (value) => {
        return value.every(contributor => 
          typeof contributor.name === 'string' &&
          typeof contributor.avatar === 'string' &&
          (contributor.link === undefined || typeof contributor.link === 'string')
        );
      }
    },
    more: {
      type: Number,
      default: 0
    },
    maxDisplay: {
      type: Number,
      default: 4
    }
  },
  data() {
    return {
      activeTooltip: null,
      tooltipStyle: {},
      arrowStyle: {}
    }
  },
  computed: {
    visibleContributors() {
      return this.contributors.slice(0, this.maxDisplay);
    }
  },
  methods: {
    showTooltip(index, event) {
      this.activeTooltip = index;
      this.$nextTick(() => {
        const target = event.target.closest('div');
        const rect = target.getBoundingClientRect();
        const containerRect = this.$el.getBoundingClientRect();
        const tooltipEl = this.$el.querySelector('.absolute.mb-2');
        
        if (tooltipEl) {
          const tooltipRect = tooltipEl.getBoundingClientRect();
          let left = rect.left - containerRect.left + rect.width / 2;
          let top = -tooltipRect.height - 8; // 8px for margin
          let arrowLeft = '50%';

          // 检查是否超出左边界
          if (left - tooltipRect.width / 2 < 0) {
            arrowLeft = `${left}px`;
            left = tooltipRect.width / 2;
          }
          // 检查是否超出右边界
          else if (left + tooltipRect.width / 2 > containerRect.width) {
            arrowLeft = `${tooltipRect.width - (containerRect.width - left)}px`;
            left = containerRect.width - tooltipRect.width / 2;
          }

          this.tooltipStyle = {
            left: `${left}px`,
            top: `${top}px`,
            transform: 'translateX(-50%)'
          };

          this.arrowStyle = {
            left: arrowLeft,
            top: '100%',
            transform: 'translateX(-50%)'
          };
        }
      });
    },
    hideTooltip() {
      this.activeTooltip = null;
    }
  }
}
</script>

<style scoped>
.group:hover .group-hover\:opacity-100 {
  transition: opacity 0.2s ease-in-out;
}

.group .group-hover\:opacity-100 {
  opacity: 0;
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.tooltip-arrow {
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #1F2937; /* 与 bg-gray-800 匹配 */
}
</style>
