<template>
  <footer class="footer text-xs">
    <div class="split bg-bg-light-3 my-6 rounded-sm w-6 h-0.5"></div>
    <p class="text-text-mid">&copy; Copyright 2024, All Rights Reserved <a class="font-bold" href="https://aicc.pro" target="_blank">AI 知识共创 - AiCC AI Creative Commons</a></p>
    <!-- <p>Power by <a class="" href="https://aicc.pro" target="_blank">AiCC AI Creative Commons</a></p> -->
    
    <!-- 添加 Contributors 组件 -->
    <div class="mt-4">
      <ContributorsLink :contributors="contributorsList" :more="additionalContributors" />
    </div>
  </footer>
</template>

<script>
import ContributorsLink from '@/components/Contributors.vue';

export default {
  name: 'AppFooter',
  components: {
    ContributorsLink
  },
  data() {
    return {
      contributorsList: [
        { name: 'Alice', avatar: 'https://avatars.githubusercontent.com/u/11069271?s=80&v=4', link: 'https://github.com/alice' },
        { name: 'Bob', avatar: 'https://avatars.githubusercontent.com/u/10386656?s=80&v=4' },
        { name: 'Charlie', avatar: 'https://avatars.githubusercontent.com/u/76263028?s=40&v=4', link: 'https://github.com/charlie' },
        { name: 'Charlie', avatar: 'https://avatars.githubusercontent.com/u/848515?s=80&v=4', link: 'https://github.com/charlie' },
      ],
      additionalContributors: 3
    }
  }
};
</script>