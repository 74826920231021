<template>
  <header class="header mb-6 px-6 logo-link">
    <a href="/">
      <img src="@/assets/aitree-logo.png" class="logo" alt="AI全景图">
    </a>
    <div class="text-xs text-text-mid">AI Tree - AI 全景手册</div>
  </header>
</template>

<script>
export default {
  name: 'AppHeader',
};
</script>
<style scoped>
.logo-link {
  display: inline-block;
}

.logo {
  width: 48px;
  height: 48px;
  margin-bottom: 8px;
}

.logo-link:hover .logo {
  animation: rainbow-effect 2s linear infinite;
}

@keyframes rainbow-effect {
  0% {
    filter: hue-rotate(0deg) saturate(100%) contrast(100%);
  }
  20% {
    filter: hue-rotate(72deg) saturate(120%) contrast(110%);
  }
  40% {
    filter: hue-rotate(144deg) saturate(140%) contrast(120%);
  }
  60% {
    filter: hue-rotate(216deg) saturate(160%) contrast(130%);
  }
  80% {
    filter: hue-rotate(288deg) saturate(180%) contrast(140%);
  }
  100% {
    filter: hue-rotate(360deg) saturate(200%) contrast(150%);
  }
}
</style>