<template>
  <div v-for="(item, index) in formattedItems" :key="index" class="update-item flex flex-col py-2 gap-1">
    <a :href="item.link" class="title text-text-normal leading-6 hover:text-brand-primary truncate">· {{ item.title }}</a>
    <!-- <div class="status flex flex-row gap-1 text-xs text-text-low content-center">
      <font-awesome-icon icon="far fa-clock fa-fw" class="mt-[2px]"/>
      <div class="date">{{ item.formattedDate }}</div>
      <div class="author">{{ item.author }}</div>
    </div> -->
    <div v-if="showTags">
      <span :class="[getTypetagClass(item.type)]">
        {{ item.type }}
      </span>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'UpdateItem',
  props: {
    updateItems: {
      type: Array,
      required: true,
      default: () => []
    },
    showTags: {
      type: Boolean,
      default: () => true
    }
  },
  setup(props) {
    const formattedItems = computed(() => {
      return props.updateItems.map(item => ({
        ...item,
        formattedDate: formatDate(item.createdAt)
      }));
    });

    const formatDate = (timestamp) => {
      if (!timestamp) return '';
      const date = new Date(parseInt(timestamp));
      return date.toLocaleDateString('zh-CN', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    };

    const getTypetagClass = (typetag) => {
      switch (typetag) {
        case '课程':
          return 'bg-red-500 text-white px-1 py-[2px] border text-xs rounded inline  border-red-500';
        case '工具':
          return 'px-1 py-[2px] border text-xs rounded inline  border-blue-200 text-blue-500';
        case '开源项目':
          return 'px-1 py-[2px] border text-xs rounded inline  border-orange-200 text-orange-500';
        case '产品':
          return 'px-1 py-[2px] border text-xs rounded inline  border-green-200 text-green-500';
        case '时间线':
          return 'px-1 py-[2px] border text-xs rounded inline  border-purple-200 text-purple-500';
        default:
          return 'px-1 py-[2px] border text-xs rounded inline border-slate-200 text-slate-500';
      }
    };

    return {
      formattedItems,
      getTypetagClass
    };
  }
}
</script>
