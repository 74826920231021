import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import apolloClient from "./apollo-client";

import "normalize.css/normalize.css";
import "tailwindcss/tailwind.css";
import "@/assets/styles/global.scss";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
/* add font awesome icon component */

import { faHome as falHome } from "@fortawesome/pro-light-svg-icons";
import { faTimeline as falTimeline } from "@fortawesome/pro-light-svg-icons";
import { faScreenUsers as falScreenUsers } from "@fortawesome/pro-light-svg-icons";
import { faGrid2 as falGrid2 } from "@fortawesome/pro-light-svg-icons";
import { faFire as falFire } from "@fortawesome/pro-light-svg-icons";
import { faBooks as falBooks } from "@fortawesome/pro-light-svg-icons";
import { faTicket as falTicket } from "@fortawesome/pro-light-svg-icons";
import { faPeopleGroup as falPeopleGroup } from "@fortawesome/pro-light-svg-icons";
import { faCircleEllipsis as falCircleEllipsis } from "@fortawesome/pro-light-svg-icons";
import { faArrowTurnDownRight as falArrowTurnDownRight } from "@fortawesome/pro-light-svg-icons";
import { faAngleRight as falAngleRight } from "@fortawesome/pro-light-svg-icons";
// regular icon
import { faHome as farHome } from "@fortawesome/pro-regular-svg-icons";
import { faTimeline as farTimeline } from "@fortawesome/pro-regular-svg-icons";
import { faScreenUsers as farScreenUsers } from "@fortawesome/pro-regular-svg-icons";
import { faGrid2 as farGrid2 } from "@fortawesome/pro-regular-svg-icons";
import { faFire as farFire } from "@fortawesome/pro-regular-svg-icons";
import { faBooks as farBooks } from "@fortawesome/pro-regular-svg-icons";
import { faTicket as farTicket } from "@fortawesome/pro-regular-svg-icons";
import { faPeopleGroup as farPeopleGroup } from "@fortawesome/pro-regular-svg-icons";
import { faUsers as farUsers } from "@fortawesome/pro-regular-svg-icons";
import { faArrowRight as farArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { faCircleArrowRight as farCircleArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { faClock as farClock } from "@fortawesome/pro-regular-svg-icons";
import { faSquare as farSquare } from "@fortawesome/pro-regular-svg-icons";
import { faCheckDouble as farCheckDouble } from "@fortawesome/pro-regular-svg-icons";
import { faBars as farBars } from "@fortawesome/pro-regular-svg-icons";
import { faCircleSmall as farCircleSmall } from "@fortawesome/pro-regular-svg-icons";
import { faDash as farDash } from "@fortawesome/pro-regular-svg-icons";
import { faHorizontalRule as farHorizontalRule } from "@fortawesome/pro-regular-svg-icons";

// solid icon
import { faCheckSquare as fasCheckSquare } from "@fortawesome/pro-solid-svg-icons";
import { faCaretDown as fasCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { faCaretUp as fasCaretUp } from "@fortawesome/pro-solid-svg-icons";
import { faMinus as fasMinus } from "@fortawesome/pro-solid-svg-icons";

import { faHome as fasHome } from "@fortawesome/pro-solid-svg-icons";
import { faTimeline as fasTimeline } from "@fortawesome/pro-solid-svg-icons";
import { faClock as fasClock } from "@fortawesome/pro-solid-svg-icons";
import { faScreenUsers as fasScreenUsers } from "@fortawesome/pro-solid-svg-icons";
import { faGrid2 as fasGrid2 } from "@fortawesome/pro-solid-svg-icons";
import { faFire as fasFire } from "@fortawesome/pro-solid-svg-icons";
import { faBooks as fasBooks } from "@fortawesome/pro-solid-svg-icons";
import { faTicket as fasTicket } from "@fortawesome/pro-solid-svg-icons";
import { faPeopleGroup as fasPeopleGroup } from "@fortawesome/pro-solid-svg-icons";
import { faUsers as fasUsers } from "@fortawesome/pro-solid-svg-icons";

const app = createApp(App);

app.component("font-awesome-icon", FontAwesomeIcon);
library.add(
  farBars,
  falHome,
  falTimeline,
  falScreenUsers,
  falGrid2,
  falFire,
  falBooks,
  falTicket,
  falPeopleGroup,
  falCircleEllipsis,
  falArrowTurnDownRight,
  falAngleRight,
  farHome,
  farTimeline,
  farScreenUsers,
  farGrid2,
  farFire,
  farBooks,
  farTicket,
  farPeopleGroup,
  farUsers,
  farArrowRight,
  farCircleArrowRight,
  farClock,
  fasCheckSquare,
  farSquare,
  farCheckDouble,
  farCircleSmall,
  farHorizontalRule,
  farDash,
  fasCaretDown,
  fasCaretUp,
  fasMinus,
  fasHome,
  fasTimeline,
  fasScreenUsers,
  fasGrid2,
  fasFire,
  fasBooks,
  fasTicket,
  fasPeopleGroup,
  fasClock,
  fasUsers,
);
app.use(router);
app.use(store);
app.provide('apollo', apolloClient);


app.mount("#app");
