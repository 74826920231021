<template>
  <div class="timeline py-4">
    <!-- <p v-if="timelineItems">Debug: {{ timelineItems.length }} items in TimeLine component</p> -->
    <!-- <p v-else>Debug: timelineItems is undefined</p> -->
    <div v-if="timelineItems && timelineItems.length > 0">
      <div v-for="(item, index) in timelineItems" :key="index" class="timeline-item border-l-2 pl-5 border-fuchsia-200  flex flex-col">
        <!-- <p>Debug: Rendering item {{ index + 1 }}</p> -->
        <div class="date text-brand-primary text-xs relative pt-2 pb-1 leading-5">
          <div class="dot absolute left-[-25px] top-4">
            <font-awesome-icon icon="far fa-circle-small fa-fw" />
          </div>
          <div class="timeline-time mt-2 mb-1" >{{ item.date || 'No date' }} </div>
        </div>
        <div class="timeline-card bg-white p-4 pl-6 flex-1">
          <a :href="item.originUrl" target="_blank"><h3 class="card-title text-base font-bold mb-1">{{ item.title }}</h3></a>
          <div class="card-detail text-text-normal text-sm mb-1 leading-6" v-html="item.content"></div>
          <a :href="item.originUrl" target="_blank" v-if="showOrigin" class="card-origin text-xs text-text-low mb-2 overflow-ellipsis">{{ item.originUrl }}</a>
          <!-- <div class="flex items-center mb-2 text-xs mt-3">
            <img v-if="showLogo" :src="item.logo" :alt="item.brandName" class="brand-logo w-5 h-5 rounded-full mr-2">
            <p v-if="showCompaney" class="brand-name text-text-normal font-semibold">{{ item.brandName }}</p>
            <p v-if="showTimeAgo" class="time-ago text-gray-500 ml-2">{{ item.createdAt }}</p>
          </div> -->
          <div v-if="showTags" class="tags mt-3">
            <span v-for="(tag, tagIndex) in item.tags" :key="tagIndex" class="tag label text-text-mid mr-2">#{{ tag }}</span>
          </div>
        </div>
      </div>
    </div>
    <p v-else>No timeline items to display.</p>
  </div>
</template>
<script>
export default {
  name: 'TimeLine',
  props: {
    timelineItems: {
      type: Array,
      required: true,
      default: () => []
    },
    showOrigin: {
      type: Boolean,
      default: true
    },
    showLogo: {
      type: Boolean,
      default: true
    },
    showCompaney: {
      type: Boolean,
      default: true
    },
    showTimeAgo: {
      type: Boolean,
      default: true
    },
    showTags: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    // 一个方法来更新 value，例如 onSelect 之类的方法
    emitUpdate(selected) {
      this.$emit('input', selected);
    }
  },
  mounted() {
    console.log('COM:TimeLine component mounted. Items:', this.timelineItems);
  },
  watch: {
    timelineItems: {
      handler(newItems) {
        console.log('COM:Timeline items updated:', newItems);
      },
      immediate: true,
      deep: true
    }
  }
}
</script>