<template>
  <div id="app" class="min-h-screen">
    <div class="main-layout flex flex-col md:flex-row leading-4">
      <!-- 汉堡菜单按钮 -->
      <button 
        class="fixed top-0 left-0 p-3 z-30 md:hidden bg-brand-primary shadow-lg rounded-br-md"
        @click="toggleSidebar"
      >
        <font-awesome-icon icon="far fa-bars" class="w-4 h-4 text-white" />
      </button>
      
      <!-- 侧边栏 -->
      <AppSidebar
        :class="sidebarClasses"
        @close-sidebar="closeSidebar"
      />

      <!-- 遮罩层 -->
      <div
        v-if="sidebarOpen && isMobile"
        class="fixed inset-0 bg-black bg-opacity-50 z-30 md:hidden"
        @click="toggleSidebar"
      ></div>

      <!-- 内容区域 -->
      <div :class="contentAreaClasses">
        <router-view />
        <AppFooter class="mt-auto"/>
      </div>
    </div>
  </div>
</template>

<script>
import AppSidebar from './components/AppSidebar.vue';
import AppFooter from './components/AppFooter.vue';

export default {
  name: 'App',
  components: {
    AppSidebar,
    AppFooter,
  },
  data() {
    return {
      sidebarOpen: false,
      isMobile: false, // 判断是否为移动设备
    };
  },
  created() {
    this.checkScreenSize(); // 检查屏幕尺寸
    window.addEventListener('resize', this.checkScreenSize); // 监听窗口变化
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkScreenSize);
  },
  methods: {
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
    },
    closeSidebar() {
      this.sidebarOpen = false;
    },
    checkScreenSize() {
      this.isMobile = window.innerWidth < 768; // 使用 md 断点
      if (!this.isMobile && this.sidebarOpen) {
        this.sidebarOpen = false; // 切换到大屏时关闭侧边栏
      }
    },
  },
  computed: {
    sidebarClasses() {
      return [
        'transition-transform duration-300',
        this.sidebarOpen ? 'translate-x-0' : '-translate-x-full',
        'md:translate-x-0 fixed md:sticky top-0 left-0 h-screen w-64',
        'z-40 overflow-y-auto'
      ];
    },
    contentAreaClasses() {
      return [
        'content-area p-6 bg-slate-100 flex-1 overflow-y-auto'
      ];
    }
  }
};
</script>

<style scoped>
.main-layout {
  min-height: 100vh;
}
</style>